import * as React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import { Flex } from "theme-ui"
import { SectionHeader } from "components/various"
import ActivityCard from "components/ServiceCard"
import { StaticImage } from "gatsby-plugin-image"
import TextLoop from "components/TextLoop"

const quotes = [
  "Never put off to tomorrow what can be done today",
  "Little brooks make great rivers",
  "United we stand, divided we fall",
  "Honesty is the best policy",
  "A good beginning, Makes a good ending",
  "Things hardly attained, are long retained",
]

const ServicesPage = () => (
  <Layout>
    <Seo title="Services" />
    <StaticImage
      src="../images/services.jpg"
      loading="eager"
      quality={95}
      formats={["auto", "webp", "avif"]}
      sx={{ width: "full", height: [200, 300] }}
    />
    <Flex
      sx={{
        flexDirection: "column",
        alignItems: "center",
        my: 2,
        p: 3,
        justifyContent: "space-around",
      }}
    >
      <SectionHeader>Services</SectionHeader>
      <Flex
        sx={{
          flexWrap: "wrap",
          pt: [3, 4],
          gap: [2, 16],
          justifyContent: "center",
          width: "100%",
        }}
      >
        <ActivityCard
          header={"Expertise comptable"}
          points={[
            "Audit des comptes",
            "Assistance comptable",
            "Evaluation d'entreprise",
            "Consolidation des comptes",
          ]}
        />
        <ActivityCard
          header={"Organisation d'entreprise"}
          points={[
            "Conception et mise en place de manuels des procédures",
            `Conception et mise en place de systèmes d'information de gestion: comptabilité
            analytique d'éxploitation intégrée aux comptabilités générale et budgétaire, système
            d'élaboration et de contrôle des budgets, système de mesure des performances (tableaux
            de bord, mesure de productivité, etc.)`,
            `Mise en place de services d'audit interne et d'organisation (avec élaboration des
            guides techniques)`,
            `Conception et mise en place de systèmes informatiques (plan de développement des
            applications, choix du matériel, formation du personnel, etc.)`,
            "Diagnostic d'entreprise, étude de restructuration, etc.",
          ]}
        />
        <ActivityCard
          header={"Conseil d'entreprise"}
          points={[
            "Audit de direction",
            `Audit opérationnel (de la fonction production, de la fonction commerciale, du système
            informatique, etc.)`,
            `Etude de projet (étude technico-économique, dossier d'agrément, dossier de financement,
            etc.)`,
            `Conseil fiscal (aspect fiscal propre à chaque opération de l'entreprise, stratégie
            fiscale, contrôle des déclarations fiscales, assistance en cours de vérification
            fiscale, etc.)`,
            `Conseil juridique (notamment en matière de droit des sociétés commerciales:
            constitution, modification du capital, fusion, etc.)`,
            `Conseil en gestion (analyse financière, plan général de développement, plan de
            financement, plan de redressement d'entreprise en difficulté, etc.)`,
            "Recrutement et formation du personnel.",
          ]}
        />
      </Flex>
    </Flex>
    <TextLoop texts={quotes} />
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Services" />

export default ServicesPage
